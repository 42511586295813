import { Space, Table, Typography } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { composeHandlers, handleCellText, handleDateCell, renderWithMaxWidth } from "../../components/TableCellRender";
import { doChangeListSettings, doFetchUsers } from "../../redux/actions/users-list";
import { RdxMySelfState } from "../../redux/reducers/myself/types";
import { RdxUsersState } from "../../redux/reducers/users-list";
import { RdxStoreState } from "../../redux/types/state";
import { RdxFetchStatus } from "../../redux/types/status";
import { routes } from "../../routes";
import { Role } from "../../services/nav-api/roles";
import { Status, User } from "../../services/nav-api/types";
import { UserStatusSwitch } from "../UsersEditor/UserStatus";

// -------------------------------------------------------------------------------------------------
// - Component
// -------------------------------------------------------------------------------------------------

export const UsersTable = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [reloadTs, setReloadTs] = React.useState(0);

  const users: RdxUsersState = useSelector((state: RdxStoreState) => state.usersList);
  const mySelf: RdxMySelfState = useSelector((state: RdxStoreState) => state.myself);
  const roles: Role[] = useSelector((state: RdxStoreState) => state.myself.userRoles ?? []);

  React.useEffect(() => {
    if (mySelf.user) {
      dispatch(doFetchUsers());
    }
  }, [reloadTs]);

  return (
    <Table
      rowKey="id"
      size="small"
      rowClassName="cursor-pointer"
      dataSource={users.data?.users}
      loading={_.isEqual(RdxFetchStatus.LOADING, users.status)}
      columns={[
        {
          title: "Name",
          render: (_val, record: User) => {
            return (
              <Space>
                <Typography.Text>{record.firstName}</Typography.Text>
                <Typography.Text>{record.lastName}</Typography.Text>
              </Space>
            );
          }
        },
        {
          title: "Email",
          dataIndex: "username",
          width: 200,
          render: composeHandlers(handleCellText, renderWithMaxWidth(200))
        },
        {
          title: "Role",
          dataIndex: "roleId",
          render: (roleId: number) =>
            _.chain(roles)
              .filter(({ id }) => _.isEqual(id, roleId))
              .head()
              .thru(r => r?.name)
              .value()
        },
        {
          title: "Added",
          dataIndex: "created",
          render: handleDateCell
        },
        {
          title: "Last Login",
          dataIndex: "lastLogin",
          render: handleDateCell
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (_status: Status, user: User) => (
            <UserStatusSwitch
              user={user}
              onChange={() => {
                setReloadTs(new Date().getTime());
              }}
            />
          )
        }
      ]}
      pagination={{
        current: users.paging.page,
        pageSize: users.paging.itemsPerPage,
        pageSizeOptions: ["50", "100", "250"],
        showLessItems: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: users.data ? users.data.total : 0
      }}
      onRow={(record: User) => ({
        onClick: () => navigate(routes.userDetails.resolve({ id: record.id.toString() }))
      })}
      onChange={({ current: page, pageSize }: PaginationProps) => {
        dispatch(doChangeListSettings(page!, pageSize!));
      }}
    />
  );
};
